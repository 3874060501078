@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
        "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #000000;
    background-image: url("../public/static/lines.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: 600px;
    background-position-y: 25%;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* Кнопка изменения темы на сайте */
.switch {
    position: relative;
    display: inline-block;
    width: 110px;
    height: 48px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #120e1c;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 40px;
    width: 40px;
    left: 4px;
    bottom: 4.5px;
    background-color: #d9d9d980;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

input:checked + .slider {
    background-color: #c9bfe3;
}

input:checked + .slider:before {
    -webkit-transform: translateX(60px);
    -ms-transform: translateX(60px);
    transform: translateX(60px);
    background-color: #4d4078;
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

/* ~~ end Кнопка изменения темы на сайте end ~~ */

.underover {
    text-decoration: dashed underline 1px;
}

@media (max-width: 900px) {
    body {
        background-position-x: 400px;
    }
}

@media (max-width: 600px) {
    body {
        background-position-x: 30px;
    }

    .switch {
        width: 55px;
        height: 24px;
    }

    .slider:before {
        content: "";
        top: 2px;
        height: 20px;
        width: 20px;
    }

    input:checked + .slider:before {
        -webkit-transform: translateX(28px);
        -ms-transform: translateX(28px);
        transform: translateX(28px);
        background-color: #4d4078;
    }
}
